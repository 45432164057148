import React from "react";
import emailjs from 'emailjs-com';
import 'react-slideshow-image/dist/styles.css'

// reactstrap components
import {
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import ExamplesNavbar from "components/Navbars/ExamplesNavbar";
import { AiOutlinePhone } from "react-icons/ai";
import { MdBadge } from "react-icons/md";
import { MdBusinessCenter } from "react-icons/md";



function LandingPage() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);



  return (
    <>
      <div className="wrapper" >
        <LandingPageHeader />
        <ExamplesNavbar/>
        <div className="section section-about-ourvision">
           <a  href="#event">
            <img
              alt="..."
              style={{
                position: 'fixed',
                right: 5,
                width: 170,
                height: 160,
                bottom: 0,
                zIndex: '999'}}
              className="box"
              src={require("assets/img/hiring.png").default }
             ></img>
           </a>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center">
                <h2 className="title">Our Mission</h2>

                <h5 className="description">
                  Our mission is to help every start-up, small and mid-size customer to unlock their full potential in Cloud Computing.
                  As a trusted advisor in your cloud journey, we value a long-term relationship over short-term gains.
                  We feel proud to help customers achieve their business objectives by providing innovative IT solutions and delivery excellence.
                </h5>
              </Col>
            </Row>
            <div id="services" className="section section-services separator separator-primary">
              <Row>
                <Col className="ml-auto mr-auto text-center">
                  <h2 className="title">Our Services</h2>

                  <div className="our solutions">

                    <Row>
                      <Col md="4">
                        <div className="solution">
                          <img
                            alt="..."
                            className="box"
                            src={require("assets/img/backup.png").default}></img>
                          <h4 className="boxtitle">Take me to Cloud</h4>

                          <p className="description">
                            Are you planning to launch applications in Cloud? Our expert professionals will help you build and create migration strategy.
                          </p>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="solution">
                          <img
                            alt="..."
                            className="box"
                            src={require("assets/img/ai.png").default}></img>
                          <h4 className="boxtitle">AI & Analytics</h4>

                          <p className="description">
                            Our goal is to help customers build better AI models to deliver accurate predictions and drive a better outcome.
                          </p>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="solution">
                          <img
                            alt="..."
                            className="box"
                            src={require("assets/img/dr.png").default}></img>
                          <h4 className="boxtitle">Cloud Backup & DR Strategy</h4>
                          <p className="description">
                            Our security experts will help build a DR strategy to prevent, detects, and recover ransomware threats.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="our solutions">
                    <Row>
                      <Col md="4">
                        <div className="solution">
                          <img
                            alt="..."
                            className="box"
                            src={require("assets/img/automation.png").default}></img>
                          <h4 className="boxtitle">DevOps Automation</h4>

                          <p className="description">
                            Our Cloud engineers can help customers build resilient architecture using CI/CD and CT best practices.
                          </p>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="solution">
                          <img
                            alt="..."
                            className="box"
                            src={require("assets/img/modernization.png").default}></img>
                          <h4 className="boxtitle">Cloud Modernization</h4>
                          <p className="description">
                            Our key focus is to optimize customers' operational overhead and cost by adopting Cloud-native services.
                          </p>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="solution">
                          <img
                            alt="..."
                            className="box"
                            src={require("assets/img/support.png").default}></img>
                          <h4 className="boxtitle">Application Support</h4>

                          <p className="description">
                            Our Support 360 program is the cost efficient application support pods desinged for mission critical applications.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>

            <div id="offerings" className="section section-offerings separator separator-primary">
              <Row>
                <Col className="ml-auto mr-auto text-center">
                  <h2 className="title">Promotional Offerings</h2>
                  <div>
                    <h5 className="description">
                      We are committed to help our customers succeed in their business.
                      Our commitment to helping small industries and startups has pushed us to offer many great value offerings for your workload.
                      A few of the key highlights are below. Feel free to reach and learn more about our core competencies.

                    </h5>
                    <br />
                    <img
                      alt="..."
                      className="img-fluid"
                      src={require("assets/img/offerings.png").default}></img>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div id="about" className="section section-team member">
          <Container>
            <Col className="ml-auto mr-auto text-center">
              <h2 className="title">About us</h2>
              <h5 className="description">
                Asterisks Cloud Consulting is a next-generation cloud consulting company with a strong focus on small and medium-sized enterprise customers.
                Our goal is to empower every business with emerging cloud technologies.
                The majority of the enterprise is still behind monolithic on-premise infrastructure and struggling to innovate with rapid consumer demand.
                With great passion and a single vision, our team has decided to educate and help customers towards the benefits and innovation of Cloud computing.
                We believe in our experience and excellence in Cloud delivery and passion driving results for customers' success.
              </h5>
            </Col>
          </Container>
          <div className="section section-story-overview">
            <Row>
              <Col>
              <img
                alt="..."
                className="img-fluid"
                src={require("assets/img/cp.png").default}></img>
              </Col>
            </Row>
          </div>
        </div>

        <div id="contact" className="section section-contact-us text-left">
          <h2 className="title" style={{ textAlign: "center" }}>Contact Us</h2>
          <p className="description" style={{ textAlign: "center" }}>Please give our communication team 24 hours to respond your query.</p>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-left" lg="5" md="3">
                <Row>
                  <Col xs={2}>
                    <img
                      alt="..."
                      className="smallbox"
                      src={require("assets/img/location.png").default}></img>
                  </Col>
                  <Col xs={10} style={{ marginTop: "10px" }}>
                    <p className="description">Kolkata, West Bengal, India</p>
                  </Col>
                </Row>
                <Row style={{ paddingTop: "20px" }}>
                  <Col xs={2}>
                    <img
                      alt="..."
                      className="smallbox"
                      src={require("assets/img/contact.png").default}></img>
                  </Col>
                  <Col xs={10} style={{ marginTop: "10px" }}>
                    <p className="description">+91-9836050500</p>
                  </Col>
                </Row>
                <Row style={{ paddingTop: "20px" }}>
                  <Col xs={2}>
                    <img
                      alt="..."
                      className="smallbox"
                      src={require("assets/img/email.png").default}></img>
                  </Col>
                  <Col xs={10} style={{ marginTop: "10px" }}>
                    <p className="description">corporate@asteriskscloud.com</p>
                  </Col>
                </Row>
              </Col>
              <Col className="ml-auto mr-auto text-left" lg="6" md="4">
                <form className="contact-form" onSubmit={sendEmail}>
                  <input type="hidden" name="to_name" />
                  <InputGroup
                    className={
                      "input-lg" + (firstFocus ? " input-group-focus" : "")
                    }>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons users_circle-08"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Full Name..."
                      type="text"
                      name="from_name"
                      onFocus={() => setFirstFocus(true)}
                      onBlur={() => setFirstFocus(false)}
                    >
                    </Input>
                  </InputGroup>
                  <InputGroup
                    className={
                      "input-lg" + (lastFocus ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons ui-1_email-85"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email..."
                      type="email"
                      name="from_email"
                      onFocus={() => setLastFocus(true)}
                      onBlur={() => setLastFocus(false)}
                    ></Input>
                  </InputGroup>
                  <InputGroup
                    className={
                      "input-lg" + (firstFocus ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                       <AiOutlinePhone/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Phone Number..."
                      type="text"
                      name="from_phno"
                      onFocus={() => setFirstFocus(true)}
                      onBlur={() => setFirstFocus(false)}
                    >
                    </Input>
                  </InputGroup>
                  <InputGroup
                    className={
                      "input-lg" + (firstFocus ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons users_circle-08"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Company..."
                      type="text"
                      name="from_company"
                      onFocus={() => setFirstFocus(true)}
                      onBlur={() => setFirstFocus(false)}
                    >
                    </Input>
                  </InputGroup>
                  <div className="textarea-container">
                    <Input
                      cols="80"
                      name="from_message"
                      placeholder="Type a message..."
                      rows="4"
                      type="textarea"
                    >
                    </Input>
                  </div>
                  <div className="send-button" style={{ textAlign: "center", padding: "20px" }}>
                    <input type="submit"
                      className="btn-round"
                      color="info"
                      href="#pablo"
                      size="lg"
                      value="Send Message" />
                  </div>
                </form>
              </Col>            </Row>
          </Container>
        </div>
        <div id="event" className="section section-event text-center">
          <h2 className="title" style={{ textAlign: "center" }}>We are Hiring</h2>
          
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-left" lg="5" md="3">
                <ul>
              <li><h4 style={{marginTop:"0px"}}>JAVA Spring Boot Developer </h4></li>
              <li><h4 style={{marginTop:"0px"}}>.Net Developer </h4></li>
              <li><h4 style={{marginTop:"0px"}}> Big Data Developer </h4></li>
              <li><h4 style={{marginTop:"0px"}}>Ab Initio Developer </h4></li>
              <li><h4 style={{marginTop:"0px"}}>Salesforce Developer </h4></li>
              <li><h4 style={{marginTop:"0px"}}>Databricks Developer </h4></li>
              </ul>
         
              </Col>
              <Col className="ml-auto mr-auto text-left" lg="6" md="4">
              <form className="contact-form" onSubmit={sendInformation}>
                  <input type="hidden" name="to_name" />
                  <InputGroup
                    className={
                      "input-lg" + (firstFocus ? " input-group-focus" : "")
                    }>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons users_circle-08"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Full Name..."
                      type="text"
                      name="full_name"
                      onFocus={() => setFirstFocus(true)}
                      onBlur={() => setFirstFocus(false)}
                    >
                    </Input>
                  </InputGroup>
                  <InputGroup
                    className={
                      "input-lg" + (lastFocus ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                      <MdBusinessCenter/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Current Role"
                      type="text"
                      name="role"
                      onFocus={() => setLastFocus(true)}
                      onBlur={() => setLastFocus(false)}
                    ></Input>
                  </InputGroup>
                  <InputGroup
                    className={
                      "input-lg" + (firstFocus ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                       <AiOutlinePhone/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Phone Number..."
                      type="text"
                      name="phno"
                      onFocus={() => setFirstFocus(true)}
                      onBlur={() => setFirstFocus(false)}
                    >
                    </Input>
                  </InputGroup>
                  <InputGroup
                    className={
                      "input-lg" + (firstFocus ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                      <MdBadge/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Total Experience"
                      type="number"
                      name="experience"
                      onFocus={() => setFirstFocus(true)}
                      onBlur={() => setFirstFocus(false)}
                    >
                    </Input>
                  </InputGroup>
                  <InputGroup
                    className={
                      "input-lg" + (firstFocus ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                      <i className="now-ui-icons ui-1_email-85"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      name="email"
                      onFocus={() => setFirstFocus(true)}
                      onBlur={() => setFirstFocus(false)}
                    >
                    </Input>
                  </InputGroup>
                  <InputGroup
                    className={
                      "input-lg" + (lastFocus ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                      <MdBusinessCenter/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Required Role"
                      type="text"
                      name="rrole"
                      onFocus={() => setLastFocus(true)}
                      onBlur={() => setLastFocus(false)}
                    ></Input>
                  </InputGroup>
                  <div className="send-button" style={{ textAlign: "center", padding: "20px" }}>
                    <input type="submit"
                      className="btn-round"
                      color="info"
                      href="#pablo"
                      size="lg"
                      value="Submit" />
                  </div>
                </form>
              </Col>
            </Row>
          </Container>

        </div>
      </div>
    </>
  );
}


function sendEmail(e) {
  e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

  emailjs.sendForm('service_9jqc7kp', 'template_uf1pzld', e.target, 'user_EJCpmz6LFa5T3VyL9n0Fh')
    .then((result) => {
      window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior)
    }, (error) => {
      console.log(error.text);
    });
}
function sendInformation(e) {
  e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

  emailjs.sendForm('service_fauvgvp', 'template_a3vobeb', e.target, 'user_EJCpmz6LFa5T3VyL9n0Fh')
    .then((result) => {
      window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior)
    }, (error) => {
      console.log(error.text);
    });
}
export default LandingPage;
