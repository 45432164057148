/*eslint-disable*/
import React from "react";
// reactstrap components
import { Container } from "reactstrap";

function DarkFooter() {
  return (
    
    <footer className="footer" data-background-color="black">
      <Container>
      
        <nav>
          
          <ul>
            <li>
            <a
                href="#services"
                
              >
              Our Offerings |
              </a>
            </li>
            <li>
              <a
                href="#about"
                
              >
                About Us | 
              </a>
            </li>
            <li>
              <a
                href=""
                target="_blank"
              >
                Blog
              </a>
            </li>
          </ul>
        </nav>
        <div className="copyright" id="copyright">
          © {new Date().getFullYear()}, {" "}

          Asterisks Cloud Consulting Pvt. Limited. All Rights Reserved

          .
        </div>
      </Container>
    </footer>
  );
}

export default DarkFooter;
