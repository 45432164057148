import React from "react";
// reactstrap components
import {
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";


function ExamplesNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}

      <Navbar className={"fixed-top " + navbarColor} color="info" expand="xl">
        <NavLink href="/">
        <img
          alt="..."
          className="logobox"
          src={require("assets/img/logo.png").default}></img>
          </NavLink>
        <Container>

          <div className="navbar-translate">


            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>

              <NavItem>
                <NavLink id="sec-1"href="/">
                  Home
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  id="sec-2"
                  href="#services"

                >
                  Services
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  id="sec-3"
                  href="#offerings"

                >
                  Offerings
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#event"
                  id="career"
                >
                 Careers
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#about"
                  id="aboutus"
                >
                  About us

                </NavLink>

              </NavItem>
              <NavItem>
                <NavLink
                  href="#contact"
                  id="contactus"
                >
                   Contact us

                </NavLink>

              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ExamplesNavbar;
