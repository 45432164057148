import React from "react";

import Typewriter from "typewriter-effect";

// reactstrap components
import { Button, Container } from "reactstrap";

// core components

function LandingPageHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <>
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/background.jpg").default + ")",
          }}
          ref={pageHeader}
        ></div>
        <div id="one" class="default first item" className="content-center">
          <Container>
            <h1 className="title" style={{color:"white"}}>
              <Typewriter
               onInit={(typewriter)=> {
               typewriter
               .typeString("Hey Cloud...")
               .pauseFor(1000)
               .deleteAll()
               .typeString("You Dream. We Deliver.")
               .pauseFor(1000)
               .deleteAll()
               .typeString("Your Trusted Advisor.")
               .pauseFor(1000)
               .deleteAll()
               .typeString("Hey Cloud...")
               .start();
               }}
               />
            </h1>
            <div className="text-center">
              <Button
                className="btn-icon btn-round"
                color="info"
                href="https://www.facebook.com/Asterisks-Cloud-Consulting-111597701397507"
                target="_blank"
              >
                <i className="fab fa-facebook-square"></i>

              </Button>
              <Button
                className="btn-icon btn-round"
                color="info"
                href="https://twitter.com/AsterisksCloud"
                target="_blank"
              >
                <i className="fab fa-twitter"></i>

              </Button>
              <Button
                className="btn-icon btn-round"
                color="info"
                href="https://www.linkedin.com/company/asterisks-cloud-consulting/"
                target="_blank"
              >

                <i className="fab fa-linkedin"></i>
              </Button>
            </div>
            
          </Container>
        </div>
      </div>
    </>
  );
}

export default LandingPageHeader;
